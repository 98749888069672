export const state = () => ({
  admins: null,
  languages: null,
  sites: null,
  autopokemoments: null,
  tradenames: null,
  maildomains: null,
  sitegroups: null,
  operatorteams: null,
  trafficsources: null,
  external_trafficsources: null,
  trafficsource_possible_costs_segments: null,
  trafficsource_api_signup_paths: null,
  datasources: null,
  datasource_batches: null,
  photo_approval_count: null,
  open_tickets_count: null,
  profile_review_count: null,
  message_report_count: null,
  quarantaine_count: null,
  countries: null,
  region_data: null,
  fictional_profile_review_count: null,
  pickup_queue_count: null,
  fetishes: null,
  operators: null,
  topics: null,
  reportreasons: null,
  shortenerdomains: null,
  smsads: null,
  mailproviders: null,
  paymentaccounts: null,
  paymentproviders: null,
  paymentmethods: null,
  useragent_os_names: [],
  useragent_os_versions: [],
  useragent_browser_names: [],
  currencies: null,
  tickettemplates: null,
  tickettemplates_hooks: null,
  tickettemplates_replaces: null,
  tickettemplates_replaces_require_site: null,
  tickettemplates_replaces_require_user: null,
  tickettemplates_attachments: null,
  revenue_rate_per_hour: null,
  ubos: null,
  bankcodes: null,
});

export const mutations = {
  set_reportreasons(state, rd) {
    state.reportreasons = rd;
  },
  set_revenue_rate_per_hour(state, rd) {
    state.revenue_rate_per_hour = rd;
  },

  set_languages(state, rd) {
    state.languages = rd;
  },
  set_sites(state, rd) {
    state.sites = rd;
  },
  set_tradenames(state, rd) {
    state.tradenames = rd;
  },
  set_maildomains(state, rd) {
    state.maildomains = rd;
  },
  set_sitegroups(state, rd) {
    state.sitegroups = rd;
  },

  set_operatorteams(state, rd) {
    state.operatorteams = rd;
  },
  set_mailproviders(state, rd) {
    state.mailproviders = rd;
  },
  set_paymentsettings(state, rd) {
    state.paymentaccounts = rd.paymentaccounts;
    state.paymentproviders = rd.paymentproviders;
    state.paymentmethods = rd.paymentmethods;
    state.bankcodes = rd.bankcodes;
  },
  set_tickettemplates(state, rd) {
    state.tickettemplates = rd.templates;
    state.tickettemplates_hooks = rd.possible_hooks;
    state.tickettemplates_attachments = rd.possible_attachments;
    state.tickettemplates_replaces = rd.replaces;
    state.tickettemplates_replaces_require_site = rd.replaces_require_site;
    state.tickettemplates_replaces_require_user = rd.replaces_require_user;
  },

  set_trafficsources(state, rd) {
    state.trafficsources = rd;
  },
  set_external_trafficsources(state, rd) {
    state.external_trafficsources = rd;
  },

  set_trafficsource_possible_costs_segments(state, rd) {
    state.trafficsource_possible_costs_segments = rd;
  },
  set_trafficsource_api_signup_paths(state, rd) {
    rd.sort();
    state.trafficsource_api_signup_paths = rd;
  },
  set_shortenerdomains(state, rd) {
    state.shortenerdomains = rd;
  },

  set_datasources(state, rd) {
    state.datasources = rd;
  },
  set_smsads(state, rd) {
    state.smsads = rd;
  },

  set_datasource_batches(state, rd) {
    state.datasource_batches = rd;
  },
  set_countries(state, rd) {
    state.countries = rd;
  },
  set_ubos(state, rd) {
    state.ubos = rd;
  },

  set_photo_approval_count(state, rd) {
    state.photo_approval_count = rd;
  },
  set_open_ticket_count(state, rd) {
    state.open_tickets_count = rd;
  },
  set_pickup_queue_count(state, rd) {
    state.pickup_queue_count = rd;
  },
  set_quarantaine_count(state, rd) {
    state.quarantaine_count = rd;
  },
  set_message_report_count(state, rd) {
    state.message_report_count = rd;
  },
  set_profile_review_count(state, rd) {
    state.profile_review_count = rd;
  },
  set_fictional_profile_review_count(state, rd) {
    state.fictional_profile_review_count = rd;
  },

  set_region_data(state, rd) {
    state.region_data = rd;
  },
  set_fetishes(state, rd) {
    state.fetishes = rd;
  },
  set_autopokemoments(state, rd) {
    state.autopokemoments = rd;
  },

  set_operators(state, rd) {
    state.operators = rd;
  },
  set_admins(state, rd) {
    state.admins = rd;
  },

  set_currencies(state, rd) {
    state.currencies = rd;
  },

  set_topics(state, rd) {
    state.topics = rd;
  },
  set_useragentdetails(state, rd) {
    state.useragent_os_names = rd.os_names;
    state.useragent_browser_names = rd.browser_names;
    state.useragent_os_versions = rd.os_versions;
  },
};
export const getters = {
  bankcodeByCode: (state) => (code) => {
    return state.bankcodes.find((x) => x.code === code);
  },
  translatedLanguages(state) {
    return state.languages?.filter((l) => l.translated);
  },
  getSiteowners(state) {
    if (state.sites === null) {
      return [];
    }
    const ids = [];
    const r = [];
    for (const key in state.sites) {
      if (!ids.includes(state.sites[key].owner_id)) {
        ids.push(state.sites[key].owner_id);
        r.push({
          id: state.sites[key].owner_id,
          name: state.sites[key].owner,
          first_site: state.sites[key].name,
        });
      }
    }
    return r;
  },
};

export const actions = {
  load_useragent_details({ commit, state }) {
    if (state.useragent_os_names.length > 0) {
      return 1;
    }
    return this.$axios.get('/api/useragent-details.php').then((res) => {
      commit('set_useragentdetails', res.data);
    });
  },
  load_reportreasons({ commit, state }, type) {
    if (state.reportreasons) {
      return 1;
    }
    return this.$axios.get('/api/reportreasons.php').then((res) => {
      commit('set_reportreasons', res.data.reasons);
    });
  },
  load_shortenerdomains({ commit, state }, type) {
    if (state.shortenerdomains) {
      return 1;
    }
    return this.$axios.get('/api/shortenerdomains.php').then((res) => {
      commit('set_shortenerdomains', res.data);
    });
  },

  load_topics({ commit, state }, type) {
    if (state.topics) {
      return 1;
    }
    return this.$axios.get('/api/topics.php').then((res) => {
      commit('set_topics', res.data);
    });
  },

  load_fetishes({ commit, state }, type) {
    if (state.fetishes) {
      return 1;
    }
    return this.$axios.get('/api/fetishes.php').then((res) => {
      commit('set_fetishes', res.data.fetishes);
    });
  },
  load_autopokemoments({ commit, state }, type) {
    if (state.autopokemoments) {
      return 1;
    }
    return this.$axios.get('/api/autopokemoments.php').then((res) => {
      commit('set_autopokemoments', res.data.autopokemoments);
    });
  },

  load_paymentsettings({ commit, state }, type) {
    if (state.paymentaccounts) {
      return 1;
    }
    return this.$axios
      .get('/api/payments.php', {
        params: {
          settings: true,
        },
      })
      .then((res) => {
        commit('set_paymentsettings', res.data);
      });
  },

  load_region_data({ commit, state }, type) {
    if (state.region_data) {
      return 1;
    }
    return this.$axios.get('/api/regions.php').then((res) => {
      commit('set_region_data', res.data);
    });
  },
  load_languages({ commit, state }, type) {
    if (state.languages) {
      return 1;
    }
    return this.$axios
      .get('/api/languages.php', {
        params: {
          locale: this.$i18n.locale,
        },
      })
      .then((res) => {
        commit('set_languages', res.data);
      });
  },
  load_currencies({ commit, state }, type) {
    if (state.currencies) {
      return 1;
    }
    return this.$axios.get('/api/currencies.php').then((res) => {
      commit('set_currencies', res.data);
    });
  },
  load_revenue_rate_per_hour({ commit, state }, type) {
    if (state.revenue_rate_per_hour) {
      return 1;
    }
    return this.$axios.get('/api/revenue-rate-hour.php').then((res) => {
      commit('set_revenue_rate_per_hour', res.data);
    });
  },

  load_photo_approval_count({ commit, state }, type) {
    return this.$axios
      .get('/api/photo-approval.php?count=1')
      .then((res) => {
        commit('set_photo_approval_count', res.data.image_count);
        commit('set_profile_review_count', res.data.profile_count);
        commit('set_quarantaine_count', res.data.quarantaine_count);
        commit('set_pickup_queue_count', res.data.pickup_queue_count);
        commit('set_message_report_count', res.data.message_report_count);
        commit('set_open_ticket_count', res.data.open_tickets);
      })
      .catch(() => {
        commit('set_photo_approval_count', 0);
        commit('set_profile_review_count', 0);
        commit('set_quarantaine_count', 0);
        commit('set_pickup_queue_count', 0);
        commit('set_message_report_count', 0);
        commit('set_open_ticket_count', 0);
        return Promise.resolve(false);
      });
  },
  load_fictional_profile_review_count({ commit, state }, type) {
    return this.$axios
      .get('/api/fictional-profile-review.php?count=1')
      .then((res) => {
        commit('set_fictional_profile_review_count', res.data.count);
      })
      .catch(() => {
        commit('set_fictional_profile_review_count', 0);
        return Promise.resolve(false);
      });
  },
  load_sites({ commit, state }, type) {
    if (state.sites) {
      return 1;
    }
    commit('set_sites', []);

    return this.$axios.get('/api/sites.php').then((res) => {
      commit('set_sites', res.data);
    });
  },
  force_reload_sites({ commit, state }, type) {
    return this.$axios
      .get('/api/sites.php', {
        params: {
          sitedomains: 1,
        },
      })
      .then((res) => {
        commit('set_sites', res.data);
      });
  },
  load_tradenames({ commit, state }, type) {
    if (state.tradenames) {
      return 1;
    }
    commit('set_tradenames', []);

    return this.$axios.get('/api/tradenames.php').then((res) => {
      commit('set_tradenames', res.data);
    });
  },
  load_maildomains({ commit, state }, type) {
    if (state.maildomains) {
      return 1;
    }
    commit('set_maildomains', []);

    return this.$axios.get('/api/maildomains.php').then((res) => {
      commit('set_maildomains', res.data);
    });
  },

  load_sitegroups({ commit, state }, type) {
    if (state.sitegroups) {
      return 1;
    }
    commit('set_sitegroups', []);
    return this.$axios.get('/api/sitegroups.php').then((res) => {
      commit('set_sitegroups', res.data);
    });
  },
  force_reload_sitegroups({ commit, state }, type) {
    return this.$axios.get('/api/sitegroups.php').then((res) => {
      commit('set_sitegroups', res.data);
    });
  },

  load_operatorteams({ commit, state }, type) {
    if (state.operatorteams) {
      return 1;
    }
    return this.$axios.get('/api/operator-teams.php').then((res) => {
      commit('set_operatorteams', res.data);
    });
  },
  load_operators({ commit, state }, type) {
    if (state.operators) {
      return 1;
    }
    return this.$axios.get('/api/operators.php').then((res) => {
      commit('set_operators', res.data);
    });
  },
  load_mailproviders({ commit, state }, type) {
    if (state.mailproviders) {
      return 1;
    }
    return this.$axios.get('/api/mailproviders.php').then((res) => {
      commit('set_mailproviders', res.data);
    });
  },
  load_tickettemplates({ commit, state }, type) {
    if (state.tickettemplates) {
      return 1;
    }
    return this.$axios.get('/api/tickettemplates.php').then((res) => {
      commit('set_tickettemplates', res.data);
    });
  },
  force_reload_tickettemplates({ commit, state }, type) {
    return this.$axios.get('/api/tickettemplates.php').then((res) => {
      commit('set_tickettemplates', res.data);
    });
  },
  load_external_trafficsources({ commit, state }, type) {
    if (state.external_trafficsources) {
      return 1;
    }
    return this.$axios.get('/api/external_trafficsource.php').then((res) => {
      commit('set_external_trafficsources', res.data.external_trafficsources);
    });
  },

  load_trafficsources({ commit, state }, type) {
    if (state.trafficsources) {
      return 1;
    }
    return this.$axios
      .get('/api/trafficsources.php')
      .then((res) => {
        commit('set_trafficsources', res.data.trafficsources);
        commit(
          'set_trafficsource_possible_costs_segments',
          res.data.possible_costs_segments
        );
        commit('set_trafficsource_api_signup_paths', res.data.api_signup_paths);
      })
      .catch((e) => {});
  },
  force_reload_trafficsources({ commit, state }, type) {
    return this.$axios.get('/api/trafficsources.php').then((res) => {
      commit('set_trafficsources', res.data.trafficsources);
      commit(
        'set_trafficsource_possible_costs_segments',
        res.data.possible_costs_segments
      );
      commit('set_trafficsource_api_signup_paths', res.data.api_signup_paths);
    });
  },
  load_datasources({ commit, state }, type) {
    if (state.trafficsources) {
      return 1;
    }
    return this.$axios.get('/api/datasources.php').then((res) => {
      commit('set_datasources', res.data);
    });
  },
  force_reload_datasources({ commit, state }, type) {
    return this.$axios.get('/api/datasources.php').then((res) => {
      commit('set_datasources', res.data);
    });
  },

  load_admins({ commit, state }, type) {
    if (state.admins) {
      return 1;
    }
    return this.$axios.get('/api/admins.php').then((res) => {
      commit('set_admins', res.data.admins);
    });
  },
  force_reload_admins({ commit, state }, type) {
    return this.$axios.get('/api/admins.php').then((res) => {
      commit('set_admins', res.data.admins);
    });
  },
  load_smsads({ commit, state }, type) {
    if (state.smsads) {
      return 1;
    }
    return this.$axios.get('/api/smsads.php').then((res) => {
      commit('set_smsads', res.data);
    });
  },
  force_reload_smsads({ commit, state }, type) {
    return this.$axios.get('/api/smsads.php').then((res) => {
      commit('set_smsads', res.data);
    });
  },

  load_datasource_batches({ commit, state }, type) {
    if (state.trafficsources) {
      return 1;
    }
    return this.$axios.get('/api/datasource-batches.php').then((res) => {
      commit('set_datasource_batches', res.data);
    });
  },
  force_reload_datasource_batches({ commit, state }, type) {
    return this.$axios.get('/api/datasource-batches.php').then((res) => {
      commit('set_datasource_batches', res.data);
    });
  },

  load_countries({ commit, state }, type) {
    if (state.countries) {
      return 1;
    }
    return this.$axios
      .get('/api/countries.php', {
        params: {
          locale: this.$i18n.locale,
          v: 3,
        },
      })
      .then((res) => {
        commit('set_countries', res.data);
      });
  },
  load_ubos({ commit, state }, type) {
    if (state.ubos) {
      return 1;
    }
    return this.$axios.get('/api/ubos.php').then((res) => {
      commit('set_ubos', res.data);
    });
  },
};
